import styled from '@emotion/styled';
import { COLORS3, FONT, MEDIA_QUERY, SPACING, ZINDEX } from '@zola/zola-ui/src/styles/emotion';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS3.WHITE_100};
  border-radius: 100px;
  width: 100%;
  max-width: 640px;
  height: 56px;
  padding: ${SPACING.S16};
  margin-bottom: ${SPACING.S24};
  position: relative;
`;

export const SearchInput = styled.input`
  border: none;
  margin-left: ${SPACING.S16};
  width: 100%;
`;

export const ResultsContainer = styled.div`
  position: absolute;
  top: calc(56px + ${SPACING.S8});
  left: 0;
  width: 100%;
  border-radius: 16px;

  display: flex;
  flex-direction: column;

  background-color: ${COLORS3.WHITE_100};
  padding: ${SPACING.S16};
  ${MEDIA_QUERY.DESKTOP} {
    padding: ${SPACING.S24};
  }
  z-index: ${ZINDEX.middle};
`;

export const DropdownText = styled.div`
  margin-bottom: ${SPACING.S16};
  ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALLER)};
  color: ${COLORS3.BLACK_075};
`;

export const ResultsList = styled.ul`
  list-style: none;
`;

export const ResultOption = styled.li<{ active: boolean }>`
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${p => (p.active ? COLORS3.BLACK_005 : 'inherit')};
  &:hover {
    cursor: pointer;
    background-color: ${COLORS3.BLACK_005};
  }
  a {
    text-decoration: none !important;
    ${FONT.textSizeCompact(FONT.FONT_SIZE_SMALLER)};
  }
`;

export const SectionTitle = styled.span`
  color: ${COLORS3.BLACK_075};
  ${FONT.textSizeCompact(FONT.FONT_SIZE_EXTRA_SMALL)};
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  outline: none;
  border: none;
  background: none;
  color: ${COLORS3.BLACK_100};
  &:hover {
    color: ${COLORS3.BLACK_075};
    cursor: pointer;
  }
  &:focus {
    outline: highlight auto 2px;
    outline: -webkit-focus-ring-color auto 2px;
  }
  z-index: ${ZINDEX.top};
`;
